import './App.css';
import About from './components/About';
import Hompage from './components/Hompage';
import NavBar from './components/NavBar';
import Services from './components/Services';
import PageTransition from './components/PageTransition';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Footer from './components/Footer';
import TrainingPage from './components/TrainingPage';
import ContactUs from './components/ContactUs';
import Gallery from './components/Gallery';



function App() {
  return (
    <Router>
      <RoutesWrapper />
    </Router>
  );
}

function RoutesWrapper() {
  const location = useLocation();

  return (
    <>
      <NavBar />
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route 
            path="/" 
            element={
              <PageTransition>
                <Hompage />
              </PageTransition>
            } 
          />
          <Route 
            path="/about" 
            element={
              <PageTransition>
                <About />
              </PageTransition>
            } 
          />
             <Route 
            path="/services" 
            element={
              <PageTransition>
                <Services />
              </PageTransition>
            } 
          />
            <Route 
            path="/trainings" 
            element={
              <PageTransition>
                <TrainingPage />
              </PageTransition>
            } 
          />
           <Route 
            path="/gallery" 
            element={
              <PageTransition>
                <Gallery />
              </PageTransition>
            } 
          />
            <Route 
            path="/contact" 
            element={
              <PageTransition>
             <ContactUs />
              </PageTransition>
            } 
          />
        </Routes>
      </AnimatePresence>
      <Footer />
    </>
  );
}

export default App;
