import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import covertop from "../assets/images/contact-top-bg.jpg";
import contactmap from "../assets/images/contact-map.jpg";
import anime from "animejs/lib/anime.es.js";

const ContactTop = styled.div`
  width: 100%;
  height: 45vh;
  background-image: url(${covertop});
  background-size: cover;

  & .ct-patch {
    width: 100%;
    height: 100%;
    background: linear-gradient(transparent, black);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
  }

  & h2 {
    color: white;
    font-family: "cdl";
    font-size: 4vw;
    width: 80%;
  }

  & h2 span {
    font-family: "cdb";
    color: #fdb73e;
  }
    & p{
    align-self:start;
    margin-left:10vw;
    font-weight:300;
    font-size:1.3vw;
    color:#fff;
    }
`;
const CovBtn = styled.button`
  color: white;
  width: 12vw;
  background: none;
  padding: 0.5vw;
  border: none;
  margin-top: 1vw;
  font-family: cdr;
  font-size: 1vw;
  border: 1px solid white;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.5s ease;

  &::before {
    content: "";
    position: absolute;
    top: var(--y);
    left: var(--x);
    width: 300%;
    height: 300%;
    background-color: #fdb73e;
    transition: transform 0.5s ease;
    transform: translate(-50%, -50%) scale(0);
    border-radius: 50%;
    z-index: -1;
  }

  &:hover::before {
    transform: translate(-50%, -50%) scale(1);
  }

  &:hover {
    background-color: #fdb73e;
    color:#000;
    letter-spacing:1px;
    border: 1px solid #fdb73e;
  }

  & span {
    position: relative;
    z-index: 1;
  }
`;
const ContactCont = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  & .contact-cont {
    width: 80%;
    height: 85vh;
    position: relative;
    margin-right: 5%;
    background-image: url(${contactmap});
    background-size: cover;
  }

  & .contact-m-patch {
    width: 100%;
    height: 100%;
    background: radial-gradient(#000000c4, black);
    display: flex;
    align-items: center;
  }

  & .contact-right,
  .contact-left {
    width: 50%;
    height: 100%;
  }

  & .contact-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  & .contact-right {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .contact-form-box {
    width: 90%;
    height: auto;
    background: #292929;
    padding: 1vw 0vw;
    position: relative;
  }
  & .form-line-box{
    width: 7vw;
    height: 7vw;
    position: absolute;
  }
  .flb1{
    top: -1vw;
    left: -1vw;
    border-top:0.2vw solid #fdb73e;
    border-left:0.2vw solid #fdb73e;
  }
  .flb2{
    top: -1vw;
    right: -1vw;
    border-top:0.2vw solid #fdb73e;
    border-right:0.2vw solid #fdb73e;
  }
  .flb3{
    bottom: -1vw;
    right: -1vw;
    border-bottom:0.2vw solid #fdb73e;
    border-right:0.2vw solid #fdb73e;
  }
  .flb4{
    bottom: -1vw;
    left: -1vw;
    border-bottom:0.2vw solid #fdb73e;
    border-left:0.2vw solid #fdb73e;
  }
  & .contact-form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1vw;
    padding:2vw 0;
  }

  & .contact-form .input-box {
    display: flex;
    flex-direction: column;
    width: 80%;
    gap: 0.2vw;
  }
  & .contact-form .input-box label {
    color: white;
    font-family: "SOurce Sans 3";
    font-size: 0.9vw;
    margin-left: 0.2vw;
  }
  & .contact-form .input-box Input {
    height: 2.5vw;
    background-color: #292929;
    border: none;
    color: white;
    border: 2px solid #ffffff29;
    padding-left: 0.5vw;
    border-radius: 0.5vw;
  }

  & .contact-left h3{
    color:#fdb73e;
    font-size: 1.5vw;
  }

  & .contact-left p{
    color:white;
    font-family: "Source Sans 3";
    font-weight: 300;
    font-size: 1vw;
  }

  & .contact-cont-box{
    width: 80%;
  height: 20vw;
  border-left: 0.5vw solid #fdb73e;
  margin-top: 2vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2vw;
  }
  & .cc-box{
    margin-left: 1vw;
  }
  & .cc-h{
    display: flex;
    align-items: center;
    color:white;
    gap: 0.5vw;
  }

  & .cc-h i{
    font-size: 1vw;
  }
  & .cc-h h3{
    color: white;
    font-size: 1vw;
    font-family: "cdl";
  }

  & .ccemail{
    display: flex;
    flex-direction: column;
    gap: 0.4vw;
  }
`;

export default function ContactUs() {

  return (
    <>
      <ContactTop>
        <div className="ct-patch">
          <h2>
            <span>CONTACT </span> US
          </h2>
         
        </div>
      </ContactTop>
      <ContactCont>
        <div className="contact-cont">
          <div className="contact-m-patch">
            <div className="contact-left">
            <h3>CALL TO ACTION</h3>
            <p>"Ready to start your learning journey with us? <br/> Contact us today to learn more about our programs or to schedule a consultation!"</p>

            <div className="contact-cont-box">
              <div className="cc-box">
                <div className="cc-h">
                <i class="fa-solid fa-location-dot"></i> <h3>Address</h3>
                </div>
                <p className="ccp">
                16/33 heerachand layout, <br /> 1st cross cox town jeevanhalli,560005
                </p>
              </div>
              <div className="cc-box">
                <div className="cc-h">
                <i class="fa-solid fa-envelope"></i> <h3>Email Address</h3>
                </div>
                <p className="ccp ccemail">
               <a href="mailto:info@trinityglobalevents.com">info@trinityglobalevents.com</a> 
               <a href="mailto:andrew@trinityglobalevents.com">andrew@trinityglobalevents.com</a> 
                
                </p>
              </div>
            </div>
            </div>
            <div className="contact-right">
              <div className="contact-form-box">
                <div className="form-line-box flb1"></div>
                <div className="form-line-box flb2"></div>
                <div className="form-line-box flb3"></div>
                <div className="form-line-box flb4"></div>
                <form action="" className="contact-form">
                  <div className="input-box">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Enter Your Name"
                    />
                  </div>
                  <div className="input-box">
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      id="email"
                      name="Email"
                      placeholder="Enter Your Email"
                    />
                  </div>
                  <div className="input-box">
                    <label htmlFor="phone">Phone</label>
                    <input
                      type="text"
                      id="phone"
                      name="Phone"
                      placeholder="Enter Your Phone Number"
                    />
                  </div>
                  <div className="input-box">
                    <label htmlFor="message">Message</label>
                    <textarea
                      name="message"
                      id="message"
                      cols="30"
                      rows="10"
                    ></textarea>
                  </div>
                  <div >
                    <CovBtn >SUBMIT</CovBtn>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ContactCont>
    </>
  );
}
