import React from 'react'
import styled from 'styled-components'
import tl from '../assets/images/tr.png'
import { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import './style.css'


const NavB = styled.nav`
position : fixed;
height:70px;
z-index:20;
display:flex;
top:0;
align-items:center;
justify-content:space-between;
width:100%;
padding-top:20px;
transition:all 0.5s ease;

&.navscroll{
 background : black;
    padding-top:0;
}


& img{
width :120px;
margin-left : 50px;
}

& ul {
width:40%;
list-style:none;
color:white;
display:flex;
margin-right:30px;
justify-content:space-around;
font-weight:500;
cursor:pointer;
}


& i{
color:#fdb73e;
transform:rotate(90deg);
transition:all 0.3s ease;
}
& ul li:hover i{
transform:rotate(180deg);
}

& ul li:hover .dropbox{
display:block;
}


& .dropbox{
background:white;
z-index:12;
position:absolute;
margin-top:0.5vw;
color:#000000ab;
font-weight:400;
display:none;
}
& .drop{
padding: 0.4vw 1.4vw;
border-bottom: 1px solid #00000033;
}
    & .drop:hover{
    background-color:#fdb73e;
    color:white;
    }
    & .drop:last-child{
    border:none;
    }
`;

export default function NavBar() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); 
  return (
    <div>

      <NavB className={`navbar ${isScrolled ? 'navscroll' : ''}`}>
      <img src={tl} alt="trinity logo" />
           <ul>
            <li>
              
             <Link to="/">HOME</Link> 
              
              </li>
            <li>
               <Link to="/about">ABOUT US</Link>
                </li>
                <li>
               <Link to="/services">SERVICES</Link>
                </li>
            {/* <li>
               <Link to="/trainings"> TRAINING <i class="fa-solid fa-caret-up"> </i></Link> 
               <div className="dropbox">
                <div className="drop">TRAINING 1</div>
                <div className="drop">TRAINING 2</div>
                <div className="drop">TRAINING 3</div>
               </div>
            </li> */}
            <li>
              <Link to="/gallery">GALLERY</Link>
            </li>
            {/* <li>CAREERS</li> */}
            <li>
              <Link to="/contact">CONTACT US</Link>
            </li>
           </ul>
      </NavB>
    </div>
  )
}
