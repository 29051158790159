import React from 'react'
import styled from 'styled-components';
import logowhite from '../assets/images/tr_white.png'
import { Link } from 'react-router-dom';


const FooterContainer = styled.div`
width:100%;
height:13vw;
background:#fdb73e;
display:flex;
align-items:center;
justify-content:center;
`;
const List = styled.li`
& a{
color:#000;}
`; 
const FooterContent = styled.div`
width:80%;
height:13vw;
display:flex;
align-items:center;
justify-content:center;

& .foot-logo-box{
width:20%;
height:11vw;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
gap:1vw;
}
& .footer-contact{
width:20%;
height:11vw;
display:flex;
flex-direction:column;
justify-content:center;
gap:1vw;
}

& .footer-contact h2{
font-weight:700;
}
& .footer-contact a{
font-weight:500;
font-size:0.9vw;
font-family:source sans 3;
color:black;
}

& .footer-contact a i{
padding-right:0.2vw;
}

& .foot-mid-box{
width:60%;
height:11vw;
display:flex;
align-items:center;
flex-direction:column;
justify-content:center;
gap:1vw;
}

.mid-line{
height:1px;
width:62%;
background:black;
opacity:0.2;
}

& .foot-mid-box ul{
list-style:none;
display:flex;
align-items:center;
justify-content:center;
gap:3vw;
width:fit-content;
font-family:source sans 3;
font-weight:500;
padding:0;
}
& .footer-line{
text-align:center;
font-family:source sans 3;
font-weight:400;
font-size:0.8vw;
}
& .foot-logo-box img{
width:8vw;
filter:invert();
}

& .social-box{
width:100%;
display:flex;
align-items:center;
justify-content:center;
gap:0.4vw;
}

& .social-box i{
padding:0.8vw;
background:black;
}
`;

export default function Footer() {
  return (
    <div>
      <FooterContainer>
        <FooterContent>
          <div className="foot-logo-box">
            <img src={logowhite} alt="trinity Logo" />
            <div className="social-box">
<a href="https://www.linkedin.com/company/trinityglobalevents/" target='_blank'><i class="fa-brands fa-linkedin-in"></i></a>
<a href="https://x.com/TrinityGlo4081" target='_blank'><i class="fa-brands fa-x-twitter"></i></a>
<a href="https://www.instagram.com/trinityglobalevents/" target='_blank'><i class="fa-brands fa-instagram"></i></a>
            </div>
          </div>
          <div className="foot-mid-box">
           <ul>
           <List>
            <Link to="/trinitytest"> HOME
            </Link></List>
           <List>
            <Link to="/about"> ABOUT
            </Link>
            </List>
            <List>
            <Link to="/about"> SERVICES
            </Link>
            </List>
           <List>
            <Link to="/gallery"> GALLERY
            </Link></List>
           <List>
            <Link to="/contact"> CONTACT US
            </Link></List>
           </ul>
           <div className="mid-line"></div>
           <div className="footer-line">© 2024 trinity. All Rights Reserved.</div>
          </div>
          <div className="footer-contact">
            <h2>GET IN TOUCH</h2>
            <a href="mailto:info@trinityglobalevents.com"><i class="fa-solid fa-envelope"></i>info@trinityglobalevents.com</a>
            <a href="mailto:andrew@trinityglobalevents.com"><i class="fa-solid fa-envelope"></i>andrew@trinityglobalevents.com</a>
            
          </div>
        </FooterContent>
      </FooterContainer>
    </div>
  )
}
