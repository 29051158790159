import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Odometer from 'react-odometerjs';
import 'odometer/themes/odometer-theme-minimal.css';


import './style.css'

const OdNums = styled.div`
  width:fit-content;
  height:10vw;
  display:flex;
  align-items:center;
  justify-content:center;
  gap:1vw;


  
`;

const TnBoxes = styled.div`
  width:23.2vw;
  height :8vw;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  position: relative;
  
  &:nth-child(4)::after {
    display:none;
  }
  
  &::after {
    content:"";
    width:0.1vw;
    height:2.5vw;
    border-radius:1vw;
    background-color:white;
    position:absolute;
    right:-0.7vw;
    opacity:0.7;
  }
`;

const Digdiv = styled.div`
color:white;
font-size:2vw;
font-family:"cdb";
font-weight:900;
display:flex;
align-items:center;
gap:1vw;
letter-spacing:0.1vw;
position:relative;
z-index:10;


`;


 const Numh = styled.div`
 color:white;
 font-family:"montserrat";
 font-size : 0.9vw;
 font-weight:500;
 margin-top:0.3vw;
 `;

export default function NumberOdometer() {
    const [values, setValues] = useState([0, 0, 0, 0]);

    useEffect(() => {
        // Set initial values after a short delay
        const timeoutId = setTimeout(() => setValues([50, 12, 500, 1000]), 50);
        return () => clearTimeout(timeoutId);
    }, []);

    return (
        <div>
            <OdNums>
                <TnBoxes>
                    <Digdiv>
            
                    <div>
                        <Odometer
                            value={values[0]}
                            format="(,ddd).dd"
                            options={{
                                animation: 'count'
                            }}
                        /> <span>+</span>
                        </div>
                    </Digdiv>
                   <Numh>EVENTS</Numh>
                </TnBoxes>
                <TnBoxes>
                    <Digdiv>
                   
                        <div>
                        <Odometer
                            value={values[1]}
                            format="(,ddd).dd"
                            options={{
                                animation: 'count'
                            }}
                        /> <span>+</span>
                        </div>
                    </Digdiv>
                    <Numh>YEARS OF EXPERIENCE</Numh>
                </TnBoxes>
                <TnBoxes>
                    <Digdiv>
               
                    <div>
                        <Odometer
                            value={values[2]}
                            format="(,ddd).dd"
                            options={{
                                animation: 'count'
                            }}
                        /> <span>+</span>
                        </div>
                    </Digdiv>
                    <Numh>PARTNERS</Numh>
                </TnBoxes>
                <TnBoxes>
                    <Digdiv>
                 
                    <div>
                        <Odometer
                            value={values[3]}
                            format="(,ddd).dd"
                            options={{
                                animation: 'count'
                            }}
                        /> <span>+</span>
                        </div>
                    </Digdiv>
                    <Numh>EXHIBITORS</Numh>
                </TnBoxes>
            </OdNums>
        </div>
    );
}
