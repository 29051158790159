import React, { useEffect, useRef, useState, useCallback } from "react";
import styled from "styled-components";
import bgImage from "../assets/images/videobg.png";
import "./style.css";
import anime from "animejs/lib/anime.es.js";
import NumberOdometer from "./NumberOdometer";
import overimg1 from "../assets/images/over.jpeg";
import overimg2 from "../assets/images/over2.jpg";
import SectorCarousel from "./SectorCarousel";
import Trainings from "./Trainings";
import useEmblaCarousel from "embla-carousel-react";
import testimonial1 from "../assets/images/testimonial1.png";
import quote from "../assets/images/quote.png";
import { Link } from 'react-router-dom';
import backGroundImg from "../assets/images/homebg.jpg"

const Cover = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center; background:url(${backGroundImg});
  background-size:cover;
  justify-content: center;
  position: relative;
`;

const Cvideo = styled.video`
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  z-index: -1;
`;

const BgOverlay = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${bgImage});
  z-index: 2;
     opacity: 0.15;
  background-repeat: repeat;
  position: absolute;
`;
const BgColorlay = styled.div`
  width: 100%;
  height: 100vh;
  background: black;
  z-index: 1;
  opacity: 0.5;
  background-repeat: repeat;
  position: absolute;
`;

const CoverCont = styled.div`
  width: 80%;
  height: 83vh;
  margin-top: 10vh;
  position: relative;
  z-index: 10;
  display: flex;
 
  flex-direction: column;
`;

const Tagline = styled.div`
  color: white;
  text-transform: uppercase;
  font-family: cdl;
  font-size: 3vw;
  font-weight: 800;
  margin-top: 25vh;

  & span {
    color: #fdb73e;
    font-family: cdb;
    letter-spacing: 0.1vw;
    font-size: 5.3vw;
    line-height: 4vw;
  }
`;

const Des = styled.div`
  color: white;
  font-family: source sans 3;
  font-size: 1vw;
  width: 60%;
  margin-top: 0.5vw;
`;

const CovBtn = styled.button`
  color: white;
  width: 12vw;
  background: none;
  padding: 0.5vw;
  border: none;
  margin-top: 2vw;
  font-family: cdr;
  font-size: 1vw;
  border: 1px solid white;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.5s ease;

  &::before {
    content: "";
    position: absolute;
    top: var(--y);
    left: var(--x);
    width: 300%;
    height: 300%;
    background-color: #fdb73e;
    transition: transform 0.5s ease;
    transform: translate(-50%, -50%) scale(0);
    border-radius: 50%;
    z-index: -1;
  }

  &:hover::before {
    transform: translate(-50%, -50%) scale(1);
  }

  &:hover {
    color: black;
    border: 1px solid #fdb73e;
  }

  & span {
    position: relative;
    z-index: 1;
  }
`;

const About = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  & .ab-sq {
    width: 4vw;
    height: 4vw;
    background-color: #fdb73e;
    position: absolute;
    right: 6vw;
    top: 6vw;
  }

  & .sq2 {
    right: 4vw;
    top: 4vw;
    background-color: #45474b;
    z-index: -1;
  }
`;

const AboutCont = styled.div`
  width: 80%;
  height: 100vh;
  display: flex;
`;

const AboutPatch = styled.div`
  width: 20%;
  height: 100vh;
  background-color: #fdb73e;
  position: absolute;
  left: 0;
  z-index: -1;
`;

const AboutLeft = styled.div`
  width: 40%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  & img {
    width: 85%;
  }
`;

const AboutRight = styled.div`
  width: 60%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;

  & h2 {
    color: white;
    font-family: "cdl";
    font-size: 3vw;
    transform: translateX(-7.5vw);
    margin-top: 6vw;
  }

  & h2 span {
    font-family: "cdb";
    color: #fdb73e;
  }

  & h2 div {
    position: absolute;
    z-index: 2;
    color: black;
    overflow: hidden;
    width: 5.2vw;
    height: 3vw;
  }

  & p {
    color: white;
    line-height: 1.4vw;
    font-size: 0.9vw;
    font-weight: 400;
    width: 100%;
    text-align: justify;
  }

  & img {
    width: 42%;
    position: absolute;
    right: 0;
    bottom: 5vw;
  }

  & .over-cir {
    width: 5vw;
    height: 5vw;
    border-radius: 50%;
    background-color: #fdb73e;
  }

  & .image-cir-box {
    width: 12vw;
    height: 12vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6vw;
    margin-top: 1vw;
  }

  & a{
    padding: 0.5vw 1vw;
    line-height: 1vw;
    padding-bottom: 0.7vw;
    background-color: #fdb73e;
    width: fit-content;
    font-size: 1vw;
    color: black;
    text-transform: uppercase;
    font-weight: 400;
  }
`;

const CircularTextWrapper = styled.div`
  position: absolute;
  width: 10vw;
  height: 10vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const CircularText = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 1vw;
  text-align: center;
  transform: ${({ rotate }) => `rotate(${rotate}deg)`};
  transform-origin: center;
  font-weight: 400;
  color: white;
  text-transform: uppercase;
`;

const Sectors = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #161616;
  display: flex;
  flex-direction: column;
  align-items: center;

  & h2 {
    color: white;
    font-family: "cdl";
    font-size: 3vw;
    font-weight: 300;
    margin-top: 3vw;
  }

  & h2 span {
    font-family: "cdb";
    color: #fdb73e;
  }

  & .sectors-cont {
    width: 80%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & p {
      color: white;
    text-align: center;
    width: 80%;
    font-size: 1vw;
    font-family: source sans 3;
    line-height: 1.7vw;
    font-weight: 300;
  }
`;

const Testimonial = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;

  & h2 {
    color: white;
    font-family: "cdl";
    font-size: 3vw;
    text-align: center;
    text-transform: uppercase;
  }

  & h2 span {
    font-family: "cdb";
    color: #fdb73e;
  }
`;

const TestimonialContainer = styled.div`
  width: 80%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TestiCarousel = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
  const [isAutoplay, setIsAutoplay] = useState(true);
  const intervalRef = useRef(null);

  const handleAutoplay = useCallback(() => {
    if (emblaApi && isAutoplay) {
      intervalRef.current = setInterval(() => {
        emblaApi.scrollNext();
      }, 100000000000);
    }
  }, [emblaApi, isAutoplay]);

  const scrollPrev = () => {
    if (emblaApi) emblaApi.scrollPrev();
  };

  const scrollNext = () => {
    if (emblaApi) emblaApi.scrollNext();
  };

  useEffect(() => {
    handleAutoplay();
    return () => clearInterval(intervalRef.current);
  }, [handleAutoplay]);

  return (
    <div className="embla" ref={emblaRef}>
      <div className="embla__container">
        <div className="embla__slide">
          <div className="test-top">
            <div className="test-img-box">
              <img src={testimonial1} alt="" />
            </div>
            <div className="test-cont">
              <img src={quote} alt="" />
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus
                aut iure rem accusamus ut vel vero eligendi commodi sed eius
                magnam, alias cumque est voluptatum sit? Tenetur reprehenderit
                tempore et quaerat veritatis eveniet sequi ipsum maiores. Dicta
                nisi dolore in facilis eaque voluptate laborum numquam dolores
                magni ab reiciendis nam quae veniam architecto consequatur,
                pariatur laudantium doloribus. Obcaecati doloremque minus,
                provident commodi corrupti libero eaque et! Facere quisquam ad
                nisi?
              </p>
            </div>
          </div>
          <div className="test-name-box">
            <div className="test-name">Lisa Thomas</div>
            <div className="test-job">Producer</div>
          </div>
        </div>
        <div className="embla__slide">
          <div className="test-top">
            <div className="test-img-box"></div>
            <div className="test-cont"></div>
          </div>
          <div className="test-name-box"></div>
        </div>
        <div className="embla__slide">
          <div className="test-top">
            <div className="test-img-box"></div>
            <div className="test-cont"></div>
          </div>
          <div className="test-name-box"></div>
        </div>
      </div>
      <button className="embla__prev" onClick={scrollPrev}>
        {" "}
        <i className="fas fa-chevron-left"></i>
      </button>
      <button className="embla__next" onClick={scrollNext}>
        {" "}
        <i className="fas fa-chevron-right"></i>
      </button>
    </div>
  );
};

function CircularTextContainer({ text }) {
  const letters = text.split("");
  const angleStep = 360 / letters.length;

  return (
    <CircularTextWrapper>
      {letters.map((letter, index) => (
        <CircularText key={index} rotate={angleStep * index}>
          {letter}
        </CircularText>
      ))}
    </CircularTextWrapper>
  );
}

export default function Hompage() {
  const [hoverPos, setHoverPos] = useState({ x: 0, y: 0 });
  const imgCirBoxRef = useRef(null);

  const handleMouseMove = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setHoverPos({ x, y });
    e.target.style.setProperty("--x", `${x}px`);
    e.target.style.setProperty("--y", `${y}px`);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            anime({
              targets: imgCirBoxRef.current,
              scale: [0, 1],
              rotate: [100, 0],
              duration: 1000,
              easing: "easeOutElastic(1, .8)",
            });
          }
        });
      },
      { threshold: 0.5 }
    );

    if (imgCirBoxRef.current) {
      observer.observe(imgCirBoxRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div>
      <Cover>
        {/* <Cvideo src={cvideo} loop autoPlay muted></Cvideo> */}
        
        <BgOverlay />
        <BgColorlay />
        <CoverCont>
          <Tagline>
          PRODUCTIVITY THROUGH, <br /> <span>TRAINING ASPECT</span> IN PLACE
          </Tagline>
          <Des>
          Empowering Aspiring Leaders to Shape a Better Future by Bridging Time-Honored Management Practices with Global Values and Insights
          </Des>
          <Link to="/about">
          <CovBtn  onMouseMove={handleMouseMove}>KNOW MORE</CovBtn>
          </Link>
        </CoverCont>
        <div style={{ position: "absolute", bottom: "0", zIndex: "10" }}>
          {/* <NumberOdometer /> */}
        </div>
      </Cover>
      <About>
        <div className="ab-sq"></div>
        <div className="ab-sq sq2"></div>
        <AboutPatch />
        <AboutCont>
          <AboutLeft>
            <img src={overimg1} alt="" />
          </AboutLeft>
          <AboutRight>
            <h2>
              <div>
                ABOUT <span>TRINITY</span>
              </div>
              ABOUT <span>TRINITY</span>
            </h2>
            <p>
              Trinity Global has etched & carved a niche in the area of
              capability building cutting across industries and functional
              areas. With a comprehensive and robust spread of offerings through
              workshops and sessions, Trinity Global out to be an ever-reliable
              knowledge partner creating differentiation through interventions
              and also measuring effectiveness to measure the true business
              transformation pre and post the entire capability building
              process.
              As the preferred global provider of business trainings and
              workshops Trinity Global strives constantly to understand the
              organizations global project requirements, expansion plans and
              tailors cutting edge training courses and workshops to cater to
              the requirements of global brands to ensure that together we meet
              our business objectives.
            </p>
            <p>Trinity Global has etched & carved a niche in the area of capability building cutting across industries and functional areas....</p>
       <Link to="/about">Read more</Link>
            <div className="image-cir-box" ref={imgCirBoxRef}>
              <div className="over-cir"></div>
              <CircularTextContainer text="Welcome to Trinity Global " />
            </div>
            <img src={overimg2} alt="" />
          </AboutRight>
        </AboutCont>
      </About>
      <Sectors>
        <h2>
        VERTICALS WE  <span>FOCUS</span>
        </h2>
        <div className="sectors-cont">
          <p>
            We have produced trainings and managed events for our partners
            across industries.making us one of the most trusted solution
            providers in the industry. We have helped our client’s change the
            way they connect, implement & distribute business to their clients.
            Our modules will encourage you to take on the biggest challenges and
            the tenacity to see them through. With various industries roaring
            back, today’s timelines increasingly trumps pricing as the most
            important factor in purchasing.
          </p>
          <SectorCarousel />
        </div>
      </Sectors>
      {/* <Trainings /> */}
      {/* <Testimonial>
        <h2>
          Voices <span>of Success</span>{" "}
        </h2>
        <TestimonialContainer>
          <TestiCarousel style={{ color: "white" }} />
        </TestimonialContainer>
      </Testimonial> */}
    </div>
  );
}
